.youtube-container {
  position: relative;
  padding-bottom: 56.25%; 
}

.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}