.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.content {
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.socialMediaButton {
  height: 100%;
}